.site-header {
  background: url('/assets/img/logo.png');
  height: 167px;
  // margin: 50px 0 1rem;
  margin: 82px 0 1rem;
}

@media (min-width: 768px) {
  .site-header {
    margin: 0 0 1rem;
  }
}

.site-title {
  display: none;
  margin: 0 0 0.8rem;
}
