img {
  max-width: 100%;
}

iframe {
  margin: 0 auto 1.5rem;
}

.soft-butter-thumb {
  margin: 0 auto 1.5rem;
  width: 272px;
}
