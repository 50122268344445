.home {
  .post-list {
    list-style: none;
    padding: 0;
  }

  .post-title {
    color: #297be0;
    font-size: 24px;
    font-weight: 700;
    line-height: 1.5;
    text-decoration: none;

    &:hover {
      color: $link-hover-color;
    }
  }

  .home-posts-li {
    margin: 0 0 2rem;
  }
}
