body {
  font-family: sans-serif;
  line-height: 1.625;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

h3 {
  color: $link-color-lighter;
  font-family: sans-serif;
  font-size: 24px;
  font-weight: 400;
  line-height: 1.625;
  margin: 0 0 1rem;
}

p {
  color: $text-primary;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 16px;
  margin: 0 0 1.625rem;
  padding: 0 0 0 0.2rem;
}

a {
  color: $link-color;

  &:hover {
    color: $link-hover-color;
  }
}

.post-meta {
  color: $text-meta;
}

.center {
  text-align: center;
}
