.image-gallery {
  display: flex;
  list-style: none;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0;

  li {
    width: 32%;

    a img {
      border-radius: 5px;
      height: 408px;
      margin: 1rem 0;
      object-fit: cover;
      width: auto;
    }
  }
}
