@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap");

nav {
  font-family: "Poppins", sans-serif;

  ul {
    list-style: none;
  }

  a {
    text-decoration: none;
  }
}

.topbar {
  position: fixed;
  top: 0;
  width: 100%;
  background: black;
}

@media (min-width: 768px) {
  .topbar {
  position: static;
  }
}

.logo{
  display: inline-block;
  color: $link-color-lighter;
  font-size: 60px;
  font-size: 2rem;
  height: 44px;
  padding: 1rem 0 0 10px;
  margin-left: 10px;
  text-decoration: none;

  // max-width: 70%;
  // line-height: 1.25;
  // font-size: 1.5rem;
}

// @media (min-width: 576px) {
//   .logo {
//     font-size: 1.75rem;
//     max-width: 80%;
//   }
// }

.nav{
  background-color: black;
  height: 100%;
  max-height: 0;
  overflow: hidden;
  position: fixed;
  transition: max-height .5s ease-out;
  width: 100%;
}

.menu {
  padding: 0 20px;

  a{
    display: block;
    padding: 16px 20px;
    color: $link-color-lighter;

    &:hover {
      color: #fff;
    }
  }
}

.hamb{
  cursor: pointer;
  float: right;
  padding: 40px 20px;
}

.hamb-line {
  background: white;
  display: block;
  height: 2px;
  position: relative;
  width: 24px;

  &::before,
  &::after{
    background: white;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    transition: all .2s ease-out;
    width: 100%;
  }

  &::before{
    top: 10px;
  }

  &::after{
    top: -10px;
  }
}

.side-menu {
  display: none;

  &:checked ~ nav{
    max-height: 100%;
  }

  &:checked ~ .hamb .hamb-line {
    background: transparent;

    &::before {
      transform: rotate(-45deg);
      top:0;
    }

    &::after {
      transform: rotate(45deg);
      top:0;
    }
  }
}

@media (min-width: 768px) {
  .logo,
  .hamb {
    display: none;
  }

  .nav{
    max-height: none;
    top: 0;
    position: static;
    float: none;
    width: initial;
  }

  .menu {
    li{
      float: left;
    }

    a {
    display: inline-block;

      &:hover{
        background-color: transparent;
        color: grey;
      }
    }
  }
}
