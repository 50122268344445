body {
  background-color: #000;
  background-image: url('/assets/img/blue-winter-evening-wallpaper-yvt.jpg');
  margin: 0;
}

.site-container {
  margin: 0 auto;
  background: black;
  color: white;
  min-height: 100vh;
}

@media (min-width: 768px) {
  .site-container {
    width: 90%;
  }
}

@media (min-width: 992px) {
  .site-container {
    width: 942px;
  }
}

.site-nav,
.page-content,
.site-footer {
  box-sizing: border-box;
  padding: 0 40px 20px;
}
